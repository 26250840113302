import React from "react";
import { Route, Switch } from "react-router-dom";

import QuizQuestions from "../QuizQuestions/QuizQuestions";

const Works = () => {
  return (
    <div className="worksRouter-container">
      <Switch>
        <Route path="/works/quizquestions" component={QuizQuestions} />
      </Switch>
    </div>
  );
};

export default Works;
