import React, { createRef } from "react";

const GlassOverlay = props => {
  const selfRef = createRef();
  // console.log(selfRef);

  return (
    <div ref={selfRef} className="GlassOverlay">
      <div className="GlassOverlay-clone" />
      {props.children}
    </div>
  );
};

export default GlassOverlay;
