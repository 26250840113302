import React from "react";
import PropTypes from "prop-types";
import { Card } from "semantic-ui-react";

const propTypes = {
  data: PropTypes.shape({
    header: PropTypes.string,
    description: PropTypes.string
  })
};
const defaultProps = {
  data: {
    onClick: () => {},
    image: "",
    header: "",
    description: "",
    meta: "",
    aspectRatio: [1, 1],
    extra: "",
    href: ""
  }
};

const KmkCard = ({ data }) => {
  return (
    <Card href={data.href} onClick={data.onClick}>
      <div
        className="kmkcard-image-container"
        style={{
          minHeight: "25vh",
          backgroundImage: `url(${data.image})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center"
        }}
      />
      <Card.Content>
        <Card.Header>{data.header}</Card.Header>
        <Card.Meta>{data.meta}</Card.Meta>
        <Card.Description>{data.description}</Card.Description>
      </Card.Content>
      {data.extra != null ? (
        <Card.Content extra={data.extra == null}>{data.extra}</Card.Content>
      ) : null}
    </Card>
  );
};

KmkCard.propTypes = propTypes;
KmkCard.defaultProps = defaultProps;

export default KmkCard;
