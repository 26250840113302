import React, { useContext, useEffect, useRef } from "react";
import { RouterContext } from "./KoumakanRouter16_7a";
import kmkLogo from "../assets/img/kmklogo.svg";

const KmkHeader = props => {
  // variables
  const fixed = "kmkheader-nav-fixed";
  const expanded = "expanded";

  // useContext
  const kmkRouterContext = useContext(RouterContext);

  // useState
  // const [fixedNav, setFixedNav] = useState("");

  // UseEffect - just like componentDidMount + componentDidUpdate
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // UseRef
  const navRef = useRef(null);
  const fixedNav = useRef(false);

  // Callback fns
  const handleItemClick = e => {
    let subpath = e.target.getAttribute("value");
    kmkRouterContext.history.push(`${subpath}`);
  };

  const handleScroll = e => {
    if (window.pageYOffset > navRef.current.clientHeight) {
      if (!fixedNav.current) {
        // console.log("fixing header");
        fixedNav.current = true;
        navRef.current.classList.toggle(fixed);
        setTimeout(() => {
          navRef.current.classList.toggle(expanded);
        }, 1);
      }
      // navRef.current.classList.toggle("expanded");
    } else {
      if (fixedNav.current) {
        fixedNav.current = false;
        // console.log("unfixing");
        navRef.current.classList.toggle(fixed);
        navRef.current.classList.toggle(expanded);
      }
    }
  };

  return (
    <section className="kmkheader kmkheader-container kmkheader-bar">
      <nav ref={navRef} className={`kmkheader-transparent kmkheader-nav `}>
        <ul>
          <img
            onClick={handleItemClick}
            value="/"
            src={kmkLogo}
            height="1rem"
            alt="home_link"
          />

          <li onClick={handleItemClick} value="/about">
            ABOUT
          </li>
          <li onClick={handleItemClick} value="/projects">
            PROJECTS
          </li>
          <li onClick={handleItemClick} value="/tools">
            TOOLS
          </li>
          <li onClick={handleItemClick} value="/contact">
            CONTACT
          </li>
        </ul>
      </nav>
    </section>
  );
};

export default KmkHeader;
