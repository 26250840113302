import React, { Component } from "react";
import { Header, Card, Icon, Responsive } from "semantic-ui-react";

import { RouterContext } from "../../container/KoumakanRouter16_7a";
import KmkFullView from "../../container/KmkFullView/KmkFullView";
import GlassOverlay from "../GlassOverlay/GlassOverlay";
import KmkCard from "../KmkCard/KmkCard";
import MainIntro from "../MainIntro/MainIntro";
import Contact from "../Contact/Contact";
import About from "../About/About";
import KmkCarousel from "kmk-carousel";

import reactIcon from "../../assets/img/react_icon.svg";
import nodeIcon from "../../assets/img/node_icon.svg";
import sassIcon from "../../assets/img/sass_icon.svg";
import nginxIcon from "../../assets/img/nginx_icon.svg";
import railsIcon from "../../assets/img/rails_icon.svg";
import mongodbIcon from "../../assets/img/mongodb_icon.svg";
import webpackIcon from "../../assets/img/webpack_icon.svg";
import babelIcon from "../../assets/img/babel_icon.svg";
import awsIcon from "../../assets/img/aws_icon.svg";
import dockerIcon from "../../assets/img/docker_icon.svg";
import threejsIcon from "../../assets/img/threejs_icon.svg";
import linuxIcon from "../../assets/img/linux_icon.svg";
import mysqlIcon from "../../assets/img/mysql_icon.svg";
import phpIcon from "../../assets/img/php_icon.svg";
import javaIcon from "../../assets/img/java_icon.svg";
import cIcon from "../../assets/img/c_icon.svg";
import cppIcon from "../../assets/img/cpp_icon.svg";
import photoshopIcon from "../../assets/img/ps_icon.svg";
import gitIcon from "../../assets/img/git_icon.svg";
import bootStrapIcon from "../../assets/img/bootstrap_icon.svg";

const items = [
  {
    href: "https://aws.koumakan.work/WeatherForecast/",
    image: "https://i.imgur.com/wjmI6kA.png",
    header: "ForecastNao!",
    description: `Simple weather forecast of 5 days with a modern UI, responsive`,
    meta: "React16.8+ Bootstrap4 Node Express",
    github: "https://github.com/kttkjl/weatherForecast"
  },
  {
    href: "/GithubIssueExplorer",
    image: "https://i.imgur.com/3kZkUnq.png",
    header: "Github Issue Explorer",
    description: `Quick check on a Github repo's issues. Utilizing Github's API v4. Comes with infinite scrolling for issues.`,
    meta: "GithubV4 Apollo GraphQL React Express.js",
    github: "https://github.com/kttkjl/GitIssuesExplorer"
  },
  {
    href: "https://aws.koumakan.work/stb/landing",
    image: "https://i.imgur.com/vhtlkQX.png",
    header: "Shoot the Bullet",
    description: `Multiplayer 3D bullet hell game made with Three.js, MMD and Socket.io. Features a jukebox and screenshoting capability with imgur`,
    meta: "Socket.io Three.js WebGL MMD"
  },
  {
    href: "https://hsweeth.herokuapp.com",
    image: "https://i.imgur.com/jDKfXUf.png",
    header: "Home Sweet Home: Smart thermostats",
    description: `Smart thermostat system with cloud-based architecture`,
    meta: "MongoDB React Express.js",
    github: "https://github.com/PeimanD/HSH_Web/tree/master/HSH_FrontEnd"
  },
  {
    href: "https://aws.koumakan.work/ReactSpotify",
    image: "https://i.imgur.com/6hYmZdN.png",
    header: "React-Spotify",
    description: `Responsive Music player Layout with playlist editing, utilizing React hooks, context, and portals, also setup to be theme-able. Playlist editing capabilities for now.`,
    meta: "React16.8+ Express.js JQuery Bootstrap4 SCSS CSS3",
    github: "https://github.com/kttkjl/React-Playlist-Spotify"
  },
  {
    // href: "/works/babble",
    image: "https://i.imgur.com/YSyBlq4.png",
    header: "Babble.js",
    description: `Create a flowing conversation, with voice detection and immediate replayability - utilizing WebAudio API. Live demo under NDA`,
    meta: "React WebAudioAPI Node.js AWS Express.js"
  },
  {
    href: "/projects",
    image: "https://i.imgur.com/cLQjC5G.png",
    header: "Kmk-carousel",
    description: `This very infinite scroll carousel`,
    meta: "React Webpack NPM",
    github: "https://www.npmjs.com/package/kmk-carousel"
  },
  {
    href: "https://github.com/kttkjl/LocationClient",
    github: "https://github.com/kttkjl/LocationClient",
    image: "https://i.imgur.com/qKa0rXN.png",
    header: "LocationClient",
    description: `Android client that tracks your current location and updates a node server with location data using socket.io`,
    meta: "Android Socket.io Express.js"
  },
  {
    image: "https://i.imgur.com/zGOuROz.png",
    href: "/COMP4711/memoryGame",
    header: "Memory Game",
    description:
      "Memory game with boxes, and simple SQL database for scores, mobile friendly",
    meta: "VanillaJS mariaDB Express.js Bootstrap"
  },
  {
    image: "https://i.imgur.com/lh9fYBi.jpg",
    header: "Webnifico : Front-end dev view",
    description:
      "Developer front-end view for testing production site functionalities - Cannot show due to NDA",
    meta: "RubyOnRails React Webpack Docker"
  },
  {
    href: "https://twitter.com/RaceToZero2017",
    image:
      "https://pbs.twimg.com/profile_banners/867177052151173121/1495651990/1500x500",
    header: "RaceToZero",
    description:
      "City of Vancouver project, a JS based game to raise food waste awareness, with twitter API to tweet the new highest scores when the database is updated",
    meta: "mySQL HTML CSS JS PHP",
    github: "https://github.com/paulszefer/RaceToZero"
  }
];

// const CSTDescription = `The Data Communications and Internetworking option specializes in network architecture design, software development, and network security skills. Courses in the option place a heavy emphasis on practical software development skills such as design, coding, debugging, and testing. Topics covered include protocol design and implementation; low-level systems programming on the Linux, Android, and Windows platforms; network application software development using the TCP/IP protocol suite; wireless protocol design; and the design and implementation of device-level communications applications. Devices used include Embedded Linux boards, Android devices, Radio Frequency Identification Devices (RFID), Wireless modems, and GPS devices. Practical work is focused around the analysis, application design, and implementation of the TCP/IP protocol suite. The environment is very team-oriented and "real world".`;
const h3style = { color: "white", fontWeight: 200 };

export const WelcomeNavContext = React.createContext({});

class Welcome extends Component {
  homeRef = React.createRef();
  aboutRef = React.createRef();
  projectsRef = React.createRef();
  toolsRef = React.createRef();
  contactRef = React.createRef();
  state = {
    loaded: false
  };

  scrollToPath = pathname => {
    switch (pathname) {
      case "/":
        this.doScroll(this.homeRef);
        break;
      case "/about":
        this.doScroll(this.aboutRef);
        break;
      case "/projects":
        this.doScroll(this.projectsRef);
        break;
      case "/tools":
        this.doScroll(this.toolsRef);
        break;
      case "/contact":
        this.doScroll(this.contactRef);
        break;
      default:
        break;
    }
  };

  doScroll = ref => {
    console.log(ref.current.offsetTop);
    window.scrollTo({
      top: ref.current.offsetTop,
      behavior: "smooth"
    });
  };

  onLoaded = () => {
    console.log("loaded");
    this.setState({ loaded: true });
  };

  componentDidMount() {
    window.addEventListener("load", this.onLoaded);
  }
  componentDidUpdate() {
    let path = this.context;
    this.scrollToPath(path.location.pathname);
  }
  componentWillUnmount() {
    window.removeEventListener("load", this.onLoaded);
  }

  render() {
    return (
      <div className="Welcome" ref={this.homeRef}>
        <KmkFullView
          backgroundImage={"https://i.imgur.com/6yK6iQT.jpg"}
          blur={3}
        >
          <MainIntro />
        </KmkFullView>
        {/* <KmkFullView backgroundImage={"https://i.imgur.com/m5lzehV.jpg"}> */}
        <span ref={this.aboutRef} />
        <KmkFullView backgroundImage={""}>
          <Header>
            <Icon name="coffee" />
            <Header.Content>
              Who is this nerd?
              <Header.Subheader as="p">
                Why am I looking at this?
              </Header.Subheader>
            </Header.Content>
          </Header>
          <About />
        </KmkFullView>
        <span ref={this.projectsRef} />
        <KmkFullView backgroundImage={""}>
          <Header>
            <Icon name="settings" />
            <Header.Content>
              Projects
              <Header.Subheader as="p">
                The ones that remotely work anyway.
              </Header.Subheader>
            </Header.Content>
          </Header>
          &nbsp;
          <Responsive maxWidth={768}>
            <Card.Group centered>
              {items.map((item, index) => {
                return <KmkCard key={index} data={item} />;
              })}
            </Card.Group>
          </Responsive>
          <Responsive as={"section"} minWidth={768}>
            <KmkCarousel items={items} />
          </Responsive>
        </KmkFullView>

        <span ref={this.toolsRef} />
        <KmkFullView
          backgroundImage={""}
          // blur={2}
        >
          <Header>
            <Icon name="wrench" />
            <Header.Content>
              Le ToolBox
              <Header.Subheader as="p">Ever expanding</Header.Subheader>
            </Header.Content>
          </Header>
          <h3 style={h3style}>Comfortable</h3>
          <GlassOverlay>
            <div className="TechGrid TechGrid-container">
              <img alt="react" src={reactIcon} />
              <img alt="node" src={nodeIcon} />
              <img alt="sass" src={sassIcon} />
              <img alt="webpack" src={webpackIcon} />
              <img alt="docker" src={dockerIcon} />
              <img alt="linux" src={linuxIcon} />
              <img alt="bootstrap" src={bootStrapIcon} />
              <img alt="git" src={gitIcon} />
            </div>
          </GlassOverlay>
          <h3 style={h3style}>Alright, needs to expand on</h3>
          <GlassOverlay>
            <div className="TechGrid TechGrid-container">
              <img alt="mongodb" src={mongodbIcon} />
              <img alt="aws" src={awsIcon} />
              <img alt="mysql" src={mysqlIcon} />
              <img alt="c" src={cIcon} />
              <img alt="photoshop" src={photoshopIcon} />
              <img alt="babel" src={babelIcon} />
              <img alt="threejs" src={threejsIcon} />
              <img alt="php" src={phpIcon} />
            </div>
          </GlassOverlay>
          <h3 style={h3style}>Used it, but not in depth or needs refreshing</h3>
          <GlassOverlay>
            <div className="TechGrid TechGrid-container">
              <img alt="java" src={javaIcon} />
              <img alt="cpp" src={cppIcon} />
              <img alt="rails" src={railsIcon} />
              <img alt="nginx" src={nginxIcon} />
            </div>
          </GlassOverlay>
        </KmkFullView>
        {/* <KmkFullView backgroundImage={"https://i.imgur.com/F0UXpeA.jpg"}> */}

        <span ref={this.contactRef} />
        <KmkFullView backgroundImage={`https://i.imgur.com/HqiuWYt.png`}>
          <Contact />
        </KmkFullView>
      </div>
    );
  }
}

Welcome.contextType = RouterContext;

export default Welcome;
