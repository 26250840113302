import React from "react";
import { Input, List } from "semantic-ui-react";
import "./QuizQuestion.scss";

const QuizQuestion = ({
  question,
  correct,
  answers,
  questionKey,
  adminMode,
  onQuestionChange,
  onAnswerChange,
  onCorrectChange
}) => {
  const handleQuestionChange = (evt, index) => {
    onQuestionChange(evt.target.value, index);
  };

  const handleAnswerChange = (evt, index, answerIndex) => {
    onAnswerChange(evt.target.value, index, answerIndex);
  };

  const handleCorrectChange = (evt, qIndex) => {
    console.log(evt.target.value, " : ", qIndex);
    onCorrectChange(parseInt(evt.target.value), qIndex);
  };

  const renderAnswers = (question, answers, adminMode) => {
    let retNode = adminMode ? (
      <>
        <h1>Question {questionKey + 1}</h1>
        <Input
          fluid
          type="textarea"
          value={question}
          onChange={evt => {
            handleQuestionChange(evt, questionKey);
          }}
        />
        <List>
          {answers.map((answer, index) => {
            return (
              <List.Item key={index}>
                <Input
                  onChange={evt => {
                    handleCorrectChange(evt, questionKey);
                  }}
                  type="radio"
                  name={`q${questionKey}`}
                  value={index}
                  checked={index === correct}
                />

                <Input
                  onChange={evt => {
                    handleAnswerChange(evt, questionKey, index);
                  }}
                  value={answer}
                />
              </List.Item>
            );
          })}
        </List>
      </>
    ) : (
      <div className="question-div">
        <h1>Question {questionKey + 1}</h1>
        <p dangerouslySetInnerHTML={setInnerHTML(question)} />
        <ol>
          {answers.map((answer, index) => {
            return (
              <li key={index}>
                <input
                  type="radio"
                  id={`q${questionKey}${index}`}
                  name={`q${questionKey}`}
                  value={index}
                />
                <label
                  htmlFor={`q${questionKey}${index}`}
                  dangerouslySetInnerHTML={setInnerHTML(answer)}
                />
              </li>
            );
          })}
        </ol>
      </div>
    );
    return retNode;
  };

  const setInnerHTML = html => {
    return {
      __html: html
    };
  };

  return (
    <div className="quizQuestion-container">
      {renderAnswers(question, answers, adminMode)}
    </div>
  );
};

export default QuizQuestion;
