import React from "react";
import { Responsive } from "semantic-ui-react";

import KmkFullView from "../../container/KmkFullView/KmkFullView";
import BlogContent from "./BlogContent";
import SideBarList from "./SideBarList";

const Blog = props => {
  const posts = [
    { title: "T1", date: Date.now(), body: "this is the body of text" },
    { title: "T2", date: Date.now(), body: "this is the body of text" },
    { title: "T3", date: Date.now(), body: "this is the body of text" },
    { title: "T4", date: Date.now(), body: "this is the body of text" }
  ];

  // const [visible, setVisible] = useState(true);

  // const toggleVisible = () => {
  //   visible ? setVisible(false) : setVisible(true);
  // };

  return (
    <KmkFullView backgroundImage={""} backgroundColor={"black"}>
      <div className="blog-root-container">
        <div className="blog-content-container">
          <BlogContent posts={posts} />
        </div>

        <Responsive minWidth={767}>
          <div className="blog-sidebar-container">
            <SideBarList />
          </div>
        </Responsive>
      </div>
    </KmkFullView>
  );
};

export default Blog;
