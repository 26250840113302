import React, { useEffect } from "react";

import KmkOverlay from "./component/KmkOverlay/KmkOverlay";
import KmkRouter from "./container/KoumakanRouter16_7a";

import RouterBase from "./component/RouterBase";
import Header from "./container/kmkHeader";
// import Footer from "./container/Footer";

// import logo from "./logo.svg";
import "./App.scss";

const App = props => {
  // Functional state here
  // const [scrollPos, setScrollPos] = useState(0);

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    /* 
      For Example:
      Update the document title using the browser API
    */
    // document.title = `You clicked ${count} times`;
    // adding scroll event
  });

  return (
    <KmkRouter>
      <div className="App" refs="App">
        <Header />
        <RouterBase />
        <KmkOverlay />
        {/* <Footer /> */}
      </div>
    </KmkRouter>
  );
};

export default App;
