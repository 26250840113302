import React from "react";
import kmkLogo from "../../assets/img/kmklogo.svg";

const MainIntro = () => {
  return (
    <div className="MainIntro">
      <div className="MainIntro-logo">
        <div className="steam" id="steam-one" />
        <div className="steam" id="steam-two" />
        <div className="steam" id="steam-three" />
        <div className="steam" id="steam-four" />
        <img alt="logo" src={kmkLogo} />
        <div className="MainIntro-text">
          <h1>WELCOME TO THE KOUMAKAN</h1>
          <h4>Where tea and coffee turn into bad code.</h4>
        </div>
      </div>
    </div>
  );
};

export default MainIntro;
