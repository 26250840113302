/**
 * Navigational overlay for the entire site
 */
import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  backgroundImage: PropTypes.string,
  backgroundColor: PropTypes.string,
  itemAlign: PropTypes.string,
  blur: PropTypes.number
};

const defaultProps = {
  backgroundImage: `https://i.imgur.com/PJABdBe.jpg`,
  itemAlign: "center",
  backgroundColor: "rgba(0,0,0,0)",
  blur: 0
};

const KmkFullView = props => {
  return (
    <div className="KmkFullView KmkFullView-container">
      <div
        className="KmkFullView KmkFullView-container-background"
        style={{
          backgroundImage: `url(${props.backgroundImage})`,
          backgroundColor: `${props.backgroundColor}`,
          WebkitFilter: `blur(${props.blur}px)`,
          MozFilter: `blur(${props.blur}px)`,
          OFilter: `blur(${props.blur}px)`,
          msFilter: `blur(${props.blur}px)`,
          filter: `blur(${props.blur}px)`
        }}
      />
      <div className={`KmkFullView KmkFullView-container-${props.itemAlign}`}>
        {/* <Container textAlign="left">{props.children}</Container> */}
        <div className={`KmkFullView-content `}>{props.children}</div>
      </div>
    </div>
  );
};

KmkFullView.propTypes = propTypes;
KmkFullView.defaultProps = defaultProps;

export default KmkFullView;
