import React from "react";
import { List, Segment } from "semantic-ui-react";

const SideBarList = ({ posts }) => {
  return (
    <Segment inverted>
      <List inverted divided>
        <List.Item>
          <List.Icon name="github" size="large" verticalAlign="middle" />
          <List.Content>
            <List.Header color="orange" as="a">
              Some BLOG POST
            </List.Header>
            <List.Description>Meta</List.Description>
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Icon name="github" size="large" verticalAlign="middle" />
          <List.Content>
            <List.Header as="a">Semantic-Org/Semantic-UI-Docs</List.Header>
            <List.Description>Updated 22 mins ago</List.Description>
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Icon name="github" size="large" verticalAlign="middle" />
          <List.Content>
            <List.Header as="a">Semantic-Org/Semantic-UI-Meteor</List.Header>
            <List.Description>Updated 34 mins ago</List.Description>
          </List.Content>
        </List.Item>
      </List>
    </Segment>
  );
};

export default SideBarList;
