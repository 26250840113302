import React from "react";
// import KmkFullView from "../../container/KmkFullView/KmkFullView";

import "./Remilia.scss";
const Remilia = props => {
  return (
    <div className="remilia remilia-container">
      <video
        autoPlay="autoplay"
        loop="loop"
        src={"https://i.imgur.com/N4F95bm.mp4"}
        type="video/mp4"
      />
      <div className="remilia-caption">
        <p>Just Remilia sipping tea</p>
      </div>
    </div>
  );
};

export default Remilia;
