import React from "react";
import { Header, Responsive, Icon } from "semantic-ui-react";
const Contact = props => {
  return (
    <>
      <Header>
        <Icon name="map marker alternate" />
        Find me on
      </Header>
      <Responsive minWidth={768} as={"div"}>
        <div className="Contact-container">
          <div className="Contact-item">
            <a href="mailto:jacky.li@koumakan.work">
              <Icon
                size="massive"
                style={{ color: "white" }}
                name="mail"
                link
              />
            </a>
            <p>jacky.li@koumakan.work</p>
          </div>
          <div className="Contact-item">
            <a href="https://github.com/kttkjl">
              <Icon
                size="massive"
                style={{ color: "white" }}
                name="github"
                link
              />
              <p>@kttkjl</p>
            </a>
          </div>
          <div className="Contact-item">
            <a href="https://www.linkedin.com/in/jackytrli/">
              <Icon
                link
                size="massive"
                style={{ color: "white" }}
                name="linkedin"
              />
              <p>jackytrli</p>
            </a>
          </div>
        </div>
      </Responsive>
      <Responsive maxWidth={767.99} as={"div"}>
        <div className="Contact-container">
          <div className="Contact-item">
            <a href="mailto:jacky.li@koumakan.work">
              <Icon size="huge" style={{ color: "white" }} name="mail" link />
              <p>jacky.li@koumakan.work</p>
            </a>
          </div>
          <div className="Contact-item">
            <a href="https://github.com/kttkjl">
              <Icon size="huge" style={{ color: "white" }} name="github" link />
              <p>@kttkjl</p>
            </a>
          </div>
          <div className="Contact-item">
            <a href="https://www.linkedin.com/in/jackytrli/">
              <Icon
                link
                size="huge"
                style={{ color: "white" }}
                name="linkedin"
              />
              <p>jackytrli</p>
            </a>
          </div>
        </div>
      </Responsive>
    </>
  );
};

export default Contact;
