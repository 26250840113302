import React from "react";
import { Grid } from "semantic-ui-react";

import profPicSmall from "../../assets/img/Prof_pic.png";
import profPicMed from "../../assets/img/Prof_picx2.png";
import profPicLarge from "../../assets/img/Prof_picx4.png";

const skillProgress = {
  React: 80,
  Node: 70,
  JavaScript: 75,
  Redux: 60,
  MySQL: 50,
  MongoDB: 40,
  Docker: 50,
  "AWS EC2": 70,
  PHP: 40,
  "Ruby/Rails": 30,
  "UI Design": 50,
  Photoshop: 50,
  "HTML(5)": 80,
  "CSS(3)": 70
};

const SkillBars = ({ skills }) => {
  return (
    <section className="SkillBars-container">
      {Object.keys(skills).map((skill, idx) => {
        return (
          <div key={idx} className="SkillBars-bar">
            <div className="SkillBars-labels">
              <span>{skill}</span>
            </div>
            <div className="SkillBars-progress">
              {skills[skill]}%
              <div
                className="SkillBars-progress-fill"
                style={{ width: `${skills[skill]}%` }}
              />
            </div>
          </div>
        );
      })}
    </section>
  );
};

const About = props => {
  return (
    <div className="About-container">
      <Grid stackable columns={2}>
        <Grid.Column>
          <div className="About-figure-container">
            <figure className="About-figure">
              <img
                alt="self_photo"
                src={profPicSmall}
                // srcSet={`${profPicSmall} 300w, ${profPicMed} 768w, ${profPicLarge} 1280w`}
              />
              <figcaption>Hi, I am Jacky</figcaption>
            </figure>
          </div>
          <p>
            You're probably here because I tricked you into checking out my
            personal profile site with a job application, since you're here...
          </p>
          <p>
            I am an aspiring Full-Stack Web Developer. I strive to learn the
            ways to make UI clean and inituitive, the UX fast and crisp, the
            backend scalable and secure.
          </p>
          <p>
            I have recently graduated with distinction from BCIT's Computer
            Systems Technology program, specalizing in{" "}
            <a
              style={{ color: "white", textDecoration: "underline" }}
              href="https://www.bcit.ca/study/programs/5500dipma#dci"
            >
              Data Communications and Internetworking
            </a>
            . Currently freelancing.
          </p>
          <p>
            Web Development is my passion and I am always on the lookout for
            more things to learn.
          </p>
        </Grid.Column>
        <Grid.Column>
          <SkillBars skills={skillProgress} />
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default About;
