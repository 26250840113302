import React from "react";
import { Route } from "react-router-dom";
import { Switch } from "react-router";

import Welcome from "./Welcome/Welcome";
import Works from "./Works/Works";
import Blog from "./Blog/Blog";
import Kar98k from "./Waifu/Kar98k";
import Remilia from "./Remilia/Remilia";

const RouterBase = function(props) {
  return (
    <div className="routerBase-container">
      <Switch>
        <Route exact path="/blog" component={Blog} />
        <Route exact path="/works" component={Works} />
        <Route exact path="/Kar98k" component={Kar98k} />
        <Route exact path="/Remilia" component={Remilia} />
        <Route path="/" component={Welcome} />
      </Switch>
    </div>
  );
};

export default RouterBase;
