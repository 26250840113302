import React from "react";
import { Header, Container } from "semantic-ui-react";
const BlogContent = ({ posts }) => {
  return (
    <Container fluid>
      <Header>This will be implemented soon (tm)</Header>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam non
        suscipit libero, ac eleifend turpis. Sed pulvinar posuere venenatis.
        Fusce ac lorem quis turpis finibus fringilla. Aliquam ligula erat,
        rhoncus ac sapien sit amet, posuere sollicitudin massa. Duis nulla diam,
        efficitur id scelerisque at, finibus in magna. Nulla sollicitudin sapien
        non aliquam fringilla. In nec justo pretium, suscipit lectus ut, porta
        est.
      </p>
    </Container>
  );
};

export default BlogContent;
