import React, { Component } from "react";
import QuizQuestion from "./QuizQuestion";
import { Button, Segment, Label } from "semantic-ui-react";
import axios from "axios";
import "./QuizQuestions.scss";

class QuizQuestions extends Component {
  state = {
    adminMode: true,
    questionsArray: [],
    quizzes: [],
    currentQuizName: ""
  };

  componentDidMount() {
    // this.getAllQuestionsFromServer();
    // this.getQuizFromServer("lab6");
    this.getQuizzesFromServer();
  }

  getAllQuestionsFromServer = async () => {
    try {
      let res = await axios.get(
        "https://aws.koumakan.work/api/quiz/get_all_questions"
      );
      let newQuestionsArray = [];
      console.log(res.data);
      res.data.forEach((question, index) => {
        question.answers = question.answers.split(/,\s?(?![^(]*\))/);
        newQuestionsArray.push(question);
      });
      this.setState(() => ({
        ...this.state,
        questionsArray: newQuestionsArray
      }));
    } catch (e) {
      console.error(e);
    }
  };

  getQuizFromServer = async quiz_name => {
    if (this.state.currentQuizName === quiz_name) {
      return;
    }
    try {
      this.setState(() => ({
        ...this.state,
        currentQuizName: quiz_name
      }));
      let res = await axios.post(
        "https://aws.koumakan.work/api/quiz/get_quiz_questions",
        {
          quiz_name: quiz_name
        }
      );
      let newQuestionsArray = [];
      res.data.forEach((question, index) => {
        question.answers = question.answers.split(/,\s?(?![^(]*\))/);
        newQuestionsArray.push(question);
      });
      this.setState(() => ({
        ...this.state,
        questionsArray: newQuestionsArray
      }));
    } catch (e) {
      console.error("specific question get error: ", e);
    }
  };

  getQuizzesFromServer = async () => {
    try {
      let res = await axios.get(
        "https://aws.koumakan.work/api/quiz/get_quizzes"
      );
      // let newQuestionsArray = [];
      console.log("quizzes from server: ", res.data);
      this.setState(() => ({
        ...this.state,
        quizzes: res.data
      }));
    } catch (e) {
      console.error("specific question get error: ", e);
    }
  };

  updateQuestionsToServer = async () => {
    try {
      await axios.post("https://aws.koumakan.work/api/quiz/save_questions", {
        questions: this.state.questionsArray,
        quiz_name: this.state.currentQuizName
      });
      // refresh on command
      this.getQuizFromServer(this.state.currentQuizName);
    } catch (e) {
      console.log(e);
    }
  };

  addEmptyQuestion = () => {
    this.setState(() => ({
      ...this.state,
      questionsArray: [
        ...this.state.questionsArray,
        {
          question: "",
          correct: 0,
          answers: ["", "", "", ""]
        }
      ]
    }));
  };

  setBorderColor = (elem, color) => {
    this.setAttributes(elem, {
      style: `
        border: 2px solid ${color}
        `
    });
  };
  setAttributes = (elem, attrs) => {
    for (let key in attrs) {
      elem.setAttribute(key, attrs[key]);
    }
  };

  submitQuestions = () => {
    let questions = this.state.questionsArray;
    let userQuestions = document.getElementsByClassName("question-div");
    let numCorrect = 0;
    // Loop through each question
    for (let i = 0; i < questions.length; ++i) {
      for (let elem of userQuestions[i].getElementsByTagName("input")) {
        // Reset this style first
        elem.labels[0].setAttribute("style", null);
        // Red user answer
        if (elem.checked) {
          this.setBorderColor(elem.labels[0], "red");
        }
        // Green correct answer
        if (parseInt(elem.value) === questions[i].correct) {
          this.setBorderColor(elem.labels[0], "lightgreen");
          if (elem.checked) {
            numCorrect++;
          }
        }
      }
    }
    alert(`Result : ${numCorrect} / ${questions.length}`);
  };

  onQuestionChange = (qText, index) => {
    let newQuestionArray = this.state.questionsArray;
    newQuestionArray[index].question = qText;
    this.setState(() => ({
      ...this.state,
      questionsArray: newQuestionArray
    }));
  };

  onAnswerChange = (aText, index, answerIndex) => {
    let newQuestionArray = this.state.questionsArray;
    newQuestionArray[index].answers[answerIndex] = aText;
    this.setState(() => ({
      ...this.state,
      questionsArray: newQuestionArray
    }));
  };

  onCorrectChange = (newCorrect, index) => {
    let newQuestionArray = this.state.questionsArray;
    newQuestionArray[index].correct = newCorrect;
    this.setState(() => ({
      ...this.state,
      questionsArray: newQuestionArray
    }));
  };

  deleteQuestion = index => {
    console.log(`deleting question: ${index + 1}`);
    let newArr = this.state.questionsArray;
    newArr.splice(index, 1);
    this.setState({
      ...this.state,
      questionsArray: newArr
    });
  };

  changeMode = mode => {
    mode
      ? this.setState(() => ({
          ...this.state,
          adminMode: false
        }))
      : this.setState(() => ({
          ...this.state,
          adminMode: true
        }));
  };

  renderQuizzesControl = () => {
    if (this.state.quizzes.length === 0) return;
    return (
      <div>
        <div>
          <Label>{"Available Quizzes"}</Label>
        </div>
        {this.state.quizzes.map((quizName, index) => {
          return (
            <Button
              color=""
              onClick={() => {
                this.getQuizFromServer(quizName);
              }}
            >
              {quizName}
            </Button>
          );
        })}
      </div>
    );
  };

  render() {
    let quizQuestions =
      this.state.questionsArray.length !== 0
        ? this.state.questionsArray.map((question, index) => {
            return (
              <Segment key={index}>
                <QuizQuestion
                  questionKey={index}
                  question={question.question}
                  correct={question.correct}
                  answers={question.answers}
                  adminMode={this.state.adminMode}
                  onQuestionChange={this.onQuestionChange}
                  onAnswerChange={this.onAnswerChange}
                  onCorrectChange={this.onCorrectChange}
                />
                {this.state.adminMode ? (
                  <Button onClick={() => this.deleteQuestion(index)}>
                    Delete
                  </Button>
                ) : null}
              </Segment>
            );
          })
        : null;
    return (
      <div className="quizQuestions-container">
        <div className="quizQuestions-mode">
          <Button color="red" onClick={() => this.changeMode(0)}>
            ADMIN MODE
          </Button>
          <Button color="blue" onClick={() => this.changeMode(1)}>
            USER MODE
          </Button>
        </div>
        {this.renderQuizzesControl()}
        {quizQuestions}
        {}
        {this.state.questionsArray.length !== 0 ? (
          this.state.adminMode ? (
            <>
              <Button color="blue" onClick={() => this.addEmptyQuestion()}>
                Add Question
              </Button>
              <Button
                color="green"
                onClick={() => this.updateQuestionsToServer()}
              >
                Save questions to server
              </Button>
            </>
          ) : (
            <Button onClick={() => this.submitQuestions()} color="blue">
              Submitterinos this Quiz
            </Button>
          )
        ) : null}
      </div>
    );
  }
}

export default QuizQuestions;
