/**
 * Navigational overlay for the entire site
 */
import React, { useEffect, useState, useRef, useContext } from "react";
import { RouterContext } from "../../container/KoumakanRouter16_7a";

const KmkOverlay = props => {
  // useContext
  const kmkRouterContext = useContext(RouterContext);
  const KmkOverlayBase = useRef(null);
  const [moreHeight, setMoreHeight] = useState(false);
  const [endOfPage, setEOP] = useState(false);
  // const [innerHeight, setInnerH] = useState(0);
  // const [scrollHeight, setScrollH] = useState(0);
  // const [pageYOff, setPageYOff] = useState(0);
  // const [] = useState(false);

  useEffect(() => {
    setMoreHeight(document.body.scrollHeight > window.innerHeight);
  }, [kmkRouterContext.history.location.pathname]);

  window.onscroll = function(ev) {
    /*end of whole page? */
    if (
      document.body.scrollHeight - (window.pageYOffset + window.innerHeight) <
      window.innerHeight / 8
    ) {
      setEOP(true);
    } else {
      setEOP(false);
    }
    /*past navbar size?*/
    if (document.body.scrollHeight > window.innerHeight / 4) {
    }
    // setInnerH(window.innerHeight);
    // setScrollH(document.body.scrollHeight);
    // setPageYOff(window.pageYOffset);
  };

  const onBottomButtonClick = e => {
    if (endOfPage) {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    } else {
      window.scrollBy({
        top: window.innerHeight - (window.scrollY % window.innerHeight),
        left: 0,
        behavior: "smooth"
      });
    }

    console.log("bot click: ", document.body.scrollHeight, window.innerHeight);
  };

  let bottomButton = () => {
    if (moreHeight && endOfPage) {
      return <div className="animatedUpArrows" />;
    } else if (moreHeight) {
      return <div className="animatedDownArrows" />;
    } else {
      return null;
    }
  };

  let sideButton = () => {};

  return (
    <div ref={KmkOverlayBase} className="KmkOverlay-base">
      <div className="KmkOverlay-base-container">
        <div className="KmkOverlay-base-container-right clickable">
          {sideButton()}
        </div>
        <div
          className="KmkOverlay-base-container-left clickable"
          onClick={onBottomButtonClick}
        />
        <div
          className="KmkOverlay-base-container-bottom clickable"
          onClick={onBottomButtonClick}
        >
          {bottomButton()}
        </div>
        {/* <div style={{ color: "white" }}>
          Debug: EOP:{JSON.stringify(endOfPage)} | more:
          {JSON.stringify(moreHeight)}| Yoff: {JSON.stringify(pageYOff)}| inner:
          {JSON.stringify(innerHeight)}| scrollH: {JSON.stringify(scrollHeight)}
          | YOFF+INNER: {JSON.stringify(pageYOff + innerHeight)} | MOD:
          {JSON.stringify(scrollHeight % innerHeight)}
        </div> */}
      </div>
    </div>
  );
};

export default KmkOverlay;
